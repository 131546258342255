export const environment = {
    production: false,
    backend: {
      service: {
        credenciales: {
          url: 'https://api.dev.gestionatupyme.cl/SincronizadorCredencialesExterno/'
        }
      }
    },
    awsCaptcha: {
      integrationUrl: 'https://4f69b1187f6d.us-east-1.captcha-sdk.awswaf.com/4f69b1187f6d/',
      apiKey: 'mStar8JjZzjywNCbgM/x6Yl1RXk1UJaH/U9/CSkygzQIpfSPbAn8kpaKK+l/hDIcOCkaDu4teF1o7HCvtQg+ME6RTspJ6yQ4gvhEKGnjZytFpryGmv3iZXWzMqmlwp8FogqEQnFBVsoaPG88GSG5wO41rsPn59XlBAAlmtUeuSVoBR6OLMYJc3UgQY9CEut5XwIMCwAehH1ywyZodqWdrbJnhI9+CGqa9XnDTJZ8JMtTOqfV94PotC5Yjli8zy6E10wTIWBE/8OSMtfxula5XyKtrzUGP0t8EFmQmDHmwZSqrApAfUEOiU1FAUwKKRQHFzez6N8/cfy8lwioEJ941xkHMe68cbYu+0NYhsC/87thKhIEAa2ZU14qTkAsi8/WVsxgoeQyNDRtA9YlXLIbY89noHz99IWZMwnOFHBH/rCmW9rCz5cYa/BaOHHYMI0bGP3E2aJvcohyy4VSdu2xfhoxqJ/h5v10Vgs/9itYOI5cypvWTLeDGdBqwE5wB5k+1j+v40ioiYKEdYfzVam8XzCoO4r2TMG9GVTg+xRRSNSG+UeBjh4Q+lpFqFimxVTOhzE9cavGJqVeaxZDrPMyf89bTAcfP/Dq8N4zX+j1KyLbHa3XZICPf4cjVh8YCUP+sxIt5C+2t7xYjCbVHVAkFiVx2h8SgJQRtj6Tzi4jmJU=_0_1'
    },
    waitTimes: {
      alerts: 10000
    }
  };